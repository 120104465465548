<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="800">
      <v-card>
        <base-loading v-if="loading.loading" />

        <v-form
          v-else
          id="form"
          ref="form"
          v-model="valid"
          enctype="multipart/form-data"
          lazy-validation
          @submit.prevent="updateGestor"
        >
          <v-card-title>
            <span class="text-h5">Configuração da declaração</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <vuetify-money
                    v-model="form.custoFixo"
                    name="custoFixo"
                    label="Custo fixo (R$) *"
                    :options="options"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model="form.pagoPeloProdutor"
                    name="pagoPeloProdutor"
                    placeholder="00%"
                    label="% Pago pelo produtor (%) *"
                    :rules="requiredRules"
                    @change="handlerForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <money-format
                    v-model="form.outrosValores"
                    name="outrosValores"
                    label="Outros valores (R$) *"
                    :options="options"
                    :rules="requiredRules"
                    :on-change="handlerForm"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="form.descricaoOutrosValoes"
                    name="descricaoOutrosValoes"
                    label="Descrição dos demais pagamentos realizados"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <vuetify-money
                    v-model="form.CustoVisitaProdutor"
                    name="CustoVisitaProdutor"
                    label="Custo da visita para o produtor (R$) * "
                    :options="options"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <money-format
                    v-model="form.custoVisitaVigor"
                    name="custoVisitaVigor"
                    label="Custo da visita para Vigor (R$) * "
                    :options="options"
                    :rules="requiredRules"
                    :on-change="setPercentageValue"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-container fluid>
                    <p>Custos pagos por:</p>
                    <v-radio-group
                      v-model="form.outrosValoresChange"
                      :required="true"
                      :rules="requiredRules"
                      inline
                      :disabled="
                        !form.custoVisitaVigor && !form.CustoVisitaProdutor
                      "
                      @change="choseValues"
                    >
                      <v-radio
                        label="Pago pela vigor *"
                        value="vigor"
                      ></v-radio>
                      <v-radio
                        label="Pago pelo produtor *"
                        value="produtor"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <small>* indica campo obrigatório</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="loading.save"
              :disabled="loading.save"
              @click="update"
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import MoneyFormat from '../../../components/base/MoneyFormat.vue'
import ReportService from '../../../services/api/reports'
import { currencyFormatter } from '../../../utils/formatter'

export default {
  name: 'DialogFields',
  components: {
    MoneyFormat,
  },
  props: {
    value: Boolean,

    relatorio: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      valid: true,

      finalizedCpp: '',

      requiredRules: [v => !!v || '* Obrigatório'],

      form: {
        custoFixo: '',
        pagoPeloProdutor: '',
        outrosValores: '',
        descricaoOutrosValoes: '',
        CustoVisitaProdutor: '',
        custoVisitaVigor: '',
        outrosValoresChange: '',
      },

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 11,
        precision: 2,
      },

      lastChose: '',

      loading: {
        save: false,
        loading: true,
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    this.load()
  },

  methods: {
    async update() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios!',
        })
      }

      this.loading.save = true

      try {
        const porcentagemcustoProdutor = {
          key: 'porcentagemcustoProdutor',
          value: this.form.pagoPeloProdutor,
        }

        const porcentagemcustoVisitaVigor = {
          key: 'porcentagemcustoVisitaVigor',
          value: currencyFormatter(
            100 - Number(this.form.pagoPeloProdutor.replace(',', '.')),
          ),
        }

        const fields = Object.entries(this.form).map(([key, value]) => ({
          key,
          value,
        }))

        fields.push(porcentagemcustoProdutor, porcentagemcustoVisitaVigor)

        const result = await ReportService.updateFields({
          id: this.relatorio.CodRelatorio,
          fields,
        })

        if (result.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'Dados atualizados com sucesso!',
          })
        } else {
          throw new Error('Não foi possível atualizar os dados!')
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: `Error ao atualizar os dados: ${JSON.stringify(error)}`,
        })
      } finally {
        this.close()
      }
    },

    async load() {
      try {
        const response = await ReportService.getFields({
          id: this.relatorio.CodRelatorio,
          fields: Object.keys(this.form),
        })

        if (response.success) {
          const form = {}

          for (const item of response.data) {
            form[item['key']] = item['value']
          }

          this.form = form
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Não foi possível carregar os dados do relatório',
        })
      } finally {
        this.loading.loading = false
      }
    },

    close() {
      this.show = false
      this.loading.save = false
      this.finalizedCpp = ''
    },

    choseValues(type) {
      if (this.lastChose === type) {
        return
      }

      if (this.lastChose === 'vigor') {
        this.form.custoVisitaVigor = String(
          this.valorVisitaVigor(this.form) - this.getOutrosValores(),
        )
      } else if (this.lastChose === 'produtor') {
        this.form.CustoVisitaProdutor = String(
          this.valorVisitaProdutor(this.form) - this.getOutrosValores(),
        )
      }

      this.lastChose = type

      if (type === 'vigor') {
        this.form.custoVisitaVigor = String(
          this.valorVisitaVigor(this.form) + this.getOutrosValores(),
        )
      }

      if (type === 'produtor') {
        this.form.CustoVisitaProdutor = String(
          this.valorVisitaProdutor(this.form) + this.getOutrosValores(),
        )
      }
    },

    getOutrosValores() {
      if (!this.form.outrosValores) return 0

      return Number(this.parsedMoney(this.form.outrosValores))
    },

    valorVisitaProdutor(form) {
      if (!form.pagoPeloProdutor) {
        return Number(this.parsedMoney(form.CustoVisitaProdutor)) || ''
      }
      return Number(this.parsedMoney(form.CustoVisitaProdutor))
    },

    valorVisitaVigor(form) {
      if (!form.pagoPeloProdutor) {
        return Number(this.parsedMoney(form.custoVisitaVigor)) || ''
      }

      return Number(this.parsedMoney(form.custoVisitaVigor))
    },

    parsedMoney(value) {
      if (typeof value === 'number') return value

      return Number(value.replace(/[^0-9.-]+/g, ''))
    },

    setPercentageValue() {
      if (
        this.form.custoVisitaVigor &&
        this.form.CustoVisitaProdutor &&
        !this.form.pagoPeloProdutor
      ) {
        const valorVisitaVigor = Number(
          this.parsedMoney(this.form.custoVisitaVigor),
        )
        const valorVisitaProdutor = Number(
          this.parsedMoney(this.form.CustoVisitaProdutor),
        )

        this.form.pagoPeloProdutor = String(
          Number(
            ((valorVisitaVigor - valorVisitaProdutor) / valorVisitaVigor) * 100,
          ).toFixed(2),
        )
      }
    },

    handlerForm() {
      if (this.form.pagoPeloProdutor) {
        const percentage = this.form.pagoPeloProdutor.replace(',', '.')

        let total = this.parsedMoney(this.form.custoFixo) * (percentage / 100)

        if (this.form.outrosValoresChange === 'produtor') {
          this.form.CustoVisitaProdutor -= this.getOutrosValores()
          total += this.getOutrosValores()
        }

        this.form.CustoVisitaProdutor = total
      }

      const percentage =
        100 - Number(this.form.pagoPeloProdutor.replace(',', '.'))
      let total = (this.parsedMoney(this.form.custoFixo) * percentage) / 100

      if (this.form.outrosValoresChange === 'vigor') {
        this.form.custoVisitaVigor -= this.getOutrosValores()
        total += this.getOutrosValores()
      }

      this.form.custoVisitaVigor = total
    },
  },
}
</script>
